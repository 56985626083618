.root {
  --color: black;
  --main-color: black;
  --width: 100%;
  --padding-x: 1rem;
  --padding-y: 1rem;
  --border-radius: 5px;
  --border-width: 1px;
  /* width: fit-content; */
  /* display: flex;
  flex-direction: row;
  align-items: center; */
}

.primary {
  --main-color: var(--clr-blue-100);
  --color: var(--clr-black-100);
}
.secondary {
  --main-color: var(--clr-secondary-200);
  --color: var(--clr-grey-100);
  --label-color: rgba(0, 0, 0, 0.7);
  --active-color: var(--clr-primary-300);
  --placeholder-color: rgba(0, 0, 0, 0.5);
}
.default {
  --main-color: inherit;
}

.error {
  --label-color: var(--active-color) !important;
  --main-color: var(--active-color);
}

.button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 2.6875rem;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  /* border-radius: 6px; */
}
.button:focus {
  outline: none;
  border: 1px solid var(--clr-primary-100);
  box-shadow: none;
}
.icon {
  position: absolute;
  right: 0.5rem;
  top: 0;
  bottom: 0;
  z-index: 2;
  height: fit-content;
  margin: auto;
}
/* borderless */

.borderless {
  border: none;
  background: none;
}

/* outline */
.outline {
  background: none;
  padding-right: min(5%, 0.9375rem);
  padding-left: min(5%, 1.875rem);
  border-radius: 3px;
  border: 1px solid var(--main-color);
}
.root label {
  text-align: start;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.25em;
  color: var(--label-color);
  @apply mb-2;
}
.outline .input {
  padding: 0.9375rem 0 0.9375rem 1.875rem;
  height: 100%;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid var(--clr-blue-100);
  border-radius: 0.1875rem;
  width: auto;
}
.outline .input::placeholder {
  color: var(--clr-secondary-200);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.outline .input:focus {
  outline: none;
  border-color: var(--clr-primary-100);
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconContainer .icon {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .iconContainer .input[data-collapse] {
    width: 0;
    transform: scaleX(0);
    margin-right: auto;
  }
}

/* .dropdown {
  bottom: 0;
} */
.item {
  display: flex;
  align-items: center;
  /* justif */
}
.dropdown {
  min-width: max-content;

  /* @apply lg:w-[12.5rem]; */
}
