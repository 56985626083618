/**
*Project:Traceability Platform
*File:education-overview-cards
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  flex: 1 1 calc(50% - 1.5625rem);
  max-width: 33.3125rem;
  height: 10.0625rem;
}
.inner {
  align-items: center;
}
.root:hover {
  cursor: pointer;
  box-shadow: 0 0 2rem 0.5rem currentColor;
  transition: all 10ms;
}
