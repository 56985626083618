/**
*Project:Traceability Platform
*File:organization
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: 100%;
}
.tableContainer {
  flex: 1 2 auto;
  overflow-x: auto;
  max-width: 100%;
}
.table {
  width: 100%;
}
.table .row:hover {
  background: var(--clr-primary-400);
}
.table .heading {
  text-align: left;
}
.table .cell {
  text-align: left;
}
.box {
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.avatar {
  width: 2.1875rem;
  height: 2.1875rem;
}
