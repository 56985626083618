/**
*Project:Traceability Platform
*File:top-bar
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  height: 100%;
  margin-bottom: 2.1875rem;
  width: 100%;
}
.search {
  width: 16.9375rem;
  border: 1px solid #dadada;
  border-radius: 5px;
  background: #ffffff;
  /* padding: 10px 0; */
  box-sizing: border-box;
}
.search:focus-within {
  border-color: var(--clr-primary-100);
}
.search .input {
  border: none;
  padding-left: 0.9375rem;
  height: 40px;
}
.search .input:focus {
  outline: none;
  border: none;
}
.search svg {
  color: #878787;
  margin-right: 0.5rem;
}
