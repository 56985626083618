/**
*Project:Traceability Platform
*File:report-analytics
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
}
.charts {
  /* background: blue; */
  width: 100%;
  height: 100%;
  padding-top: 3.125rem;
  gap: min(3.125rem, 2%);
}
@media only screen and (max-width: 1300px) {
  .charts {
    flex-wrap: wrap;
    gap: 2rem;
  }
}
