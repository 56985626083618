/**
*Project:Traceability Platform
*File:badge
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: 4.75rem;
  height: 1.5625rem;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 0.625rem;
  line-height: 120%;
}
@media only screen and (max-width: 1000px) {
  .root {
    width: min(50px, 12vw);
    aspect-ratio: 76/25;
    font-size: 7px;
  }
}
