.root {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
}
.banner {
  flex: 1 1 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--clr-black-100);
  position: relative;
}
.content {
  flex: 1 1 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.banner .icon {
  font-size: 14.1875rem;
}

.banner .label {
  position: absolute;
  bottom: 2.4081rem;
  left: 2.75rem;
  color: var(--clr-secondary-100);
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
}
.content .label {
  position: absolute;
  bottom: 20px;
  display: none;
  z-index: 10;
}

@media only screen and (max-width: 600px) {
  .root {
    flex-direction: column;
  }
  .banner {
    flex: 1 1 30%;
  }
  .content {
    flex: 1 1 70%;
    max-height: 70%;
    overflow-y: scroll;
    margin-bottom: auto;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
  }
  .banner .label {
    position: relative;
    font-size: 10px;
    text-align: center;
    bottom: unset;
    left: unset;
    margin-top: 1rem;
    display: none;
  }
  .banner .icon {
    font-size: 8rem;
  }
  .content .label {
    position: absolute;
    bottom: 20px;
    z-index: 10;
  }
}
