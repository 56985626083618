.davys-grey {
    color: #5B5B5B;
}
.full-black {
    color: #000000;
}
.white {
    color: #FFFFFF;
}
.spanish-gray {
    color: #929493;
}
