/**
*Project:Traceability Platform
*File:table
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: auto;
  display: flex;
  flex-direction: column;
}
.tableContainer {
  flex: 1 2 auto;
  overflow-x: auto;
  max-width: 100%;
}
.table {
  width: 100%;
}
.table .row:hover {
  background: var(--clr-primary-400);
}
.table .heading {
  text-align: left;
}
.table .cell {
  text-align: left;
}

.box {
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

@media only screen and (max-width: 1000px) {
  .box {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
  .table .heading {
    text-align: center;
    padding: 0.4rem 0.4rem;
  }
  .table .cell {
    text-align: center;
    /* padding: 0; */
  }
  .collapse {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1000px) {
  .header {
    height: 5rem;
  }
}
