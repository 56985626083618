.root {
  border-radius: 100rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.green {
  background: #e7ffd8;
  color: #66b134;
}
.yellow {
  background: #feedc8;
  color: #cd951d;
}
.red {
  background: #ffe9e9;
  color: #da474d;
}
