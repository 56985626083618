@font-face {
  font-family: "Lufga Bold";
  font-display: swap;
  font-weight: bold;
  src: local("Lufga Bold"), url(./fonts/lufga/LufgaBold.ttf) format("truetype");
}
@font-face {
  font-family: "Lufga Medium";
  font-display: swap;
  font-weight: 500;
  src: local("Lufga Medium"),
    url(./fonts/lufga/LufgaMedium.ttf) format("truetype");
}
@font-face {
  font-family: "Lufga Regular";
  font-display: swap;
  font-weight: 400;
  src: local("Lufga Regular"),
    url(./fonts/lufga/LufgaRegular.ttf) format("truetype");
}
@font-face {
  font-family: "Lufga";
  font-display: swap;
  src: local("Lufga"), url(./fonts/lufga/LufgaRegular.ttf) format("truetype");
}
@font-face {
  font-family: "Lufga SemiBold";
  font-display: swap;
  font-weight: 600;
  src: local("Lufga SemiBold"),
    url(./fonts/lufga/LufgaSemiBold.ttf) format("truetype");
}

/* Inter */
@font-face {
  font-family: "Inter Regular";
  font-weight: 400;
  src: local("Inter Regular"),
    url(./fonts/inter/Inter-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Inter SemiBold";
  font-weight: 600;
  src: local("Inter"), url(./fonts/inter/Inter-SemiBold.otf) format("opentype");
}

/* Mullish regular, semi-bold and bold*/
@font-face {
  font-family: "Mullish Regular";
  font-weight: 400;
  src: local("Mullish Regular"),
    url(./fonts/mullish/Mulish-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Mullish SemiBold";
  font-weight: 600;
  src: local("Mullish SemiBold"),
    url(./fonts/mullish/Mulish-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Mullish Bold";
  font-weight: 700;
  src: local("Mullish Bold"),
    url(./fonts/mullish/Mulish-Bold.ttf) format("truetype");
}

/* Product Sans regular*/
@font-face {
  font-family: "Product Sans Regular";
  font-weight: 400;
  src: local("Product Sans Regular"),
    url(./fonts/productsans/Product-Sans-Regular.ttf) format("truetype");
}

/* Roboto Medium, Bold*/
@font-face {
  font-family: "Roboto Medium";
  font-weight: 500;
  src: local("Roboto Medium"),
    url(./fonts/roboto/Roboto-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto Bold";
  font-weight: 700;
  src: local("Roboto Bold"),
    url(./fonts/roboto/Roboto-Bold.ttf) format("truetype");
}
/* Uber move medium, bold*/
@font-face {
  font-family: "Uber Move Medium";
  font-weight: 500;
  src: local("Uber Move Medium"),
    url(./fonts/uber/UberMoveMedium.otf) format("opentype");
}

@font-face {
  font-family: "Uber Move";
  font-weight: 500;
  src: local("Uber Move"),
    url(./fonts/uber/UberMoveMedium.otf) format("opentype");
}

@font-face {
  font-family: "Uber Move Bold";
  font-weight: 700;
  src: local("Uber Move Bold"),
    url(./fonts/uber/UberMove-Bold.ttf) format("truetype");
}
/*SFPro Bold */
@font-face {
  font-family: "SFPro Bold";
  font-weight: 700;
  src: local("SFPro Bold"),
    url(./fonts/sfpro/SFProDisplay-Bold.ttf) format("truetype");
}

/* ITC Avant Garde Gothic */
@font-face {
  font-family: "ITC Avant Garde Gothic";
  font-weight: 500;
  src: local("ITC Avant Garde Gothic"),
    url(./fonts/itc/ITCMedium.otf) format("opentype");
}

/* Font Classes */
/* Font Family: Lufga */
.lufga-bold {
  font-family: "Lufga Bold", serif;
}
.lufga-medium {
  font-family: "Lufga Medium", serif;
}
.lufga-regular {
  font-family: "Lufga Regular", serif;
}
.lufga-semi-bold {
  font-family: "Lufga SemiBold", serif;
}

/* Font Family: Inter */
.inter-regular {
  font-family: "Inter Regular", serif;
}
.inter-semi-bold {
  font-family: "Inter SemiBold", serif;
}

/* Font Family: Mullish */
.mullish-regular {
  font-family: "Mullish Regular", serif;
}
.mullish-semi-bold {
  font-family: "Mullish SemiBold", serif;
}
.mullish-bold {
  font-family: "Mullish Bold", serif;
}

/* Font Family: Product Sans */
.product-sans-regular {
  font-family: "Product Sans Regular", serif;
}

/* Font Family: Roboto */
.roboto-medium {
  font-family: "Roboto Medium", serif;
}
.roboto-bold {
  font-family: "Roboto Bold", serif;
}

/* Font Family: Uber Move */
.uber-move-medium {
  font-family: "Uber Move Medium", serif;
}
.uber-move-bold {
  font-family: "Uber Move Bold", serif;
}

/* Font Family: SFPro */
.sfpro-bold {
  font-family: "SFPro Bold", serif;
}

/* Font Family: ITC Avant Garde Gothic */
.itc-avant-garde-gothic {
  font-family: "ITC Avant Garde Gothic", serif;
}

/* Font Weights */
/* Font Weight: Lufga */
.lufga-bold {
  font-weight: 700;
}
.lufga-medium {
  font-weight: 500;
}
.lufga-regular {
  font-weight: 400;
}
.lufga-semi-bold {
  font-weight: 600;
}

/* Font Weight: Inter */
.inter-regular {
  font-weight: 400;
}
.inter-semi-bold {
  font-weight: 600;
}

/* Font Weight: Mullish */
.mullish-regular {
  font-weight: 400;
}
.mullish-semi-bold {
  font-weight: 600;
}
.mullish-bold {
  font-weight: 700;
}

/* Font Weight: Product Sans */
.product-sans-regular {
  font-weight: 400;
}

/* Font Weight: Roboto */
.roboto-medium {
  font-weight: 500;
}
.roboto-bold {
  font-weight: 700;
}

/* Font Weight: Uber Move */
.uber-move-medium {
  font-weight: 500;
}
.uber-move-bold {
  font-weight: 700;
}

/* Font Weight: SFPro */
.sfpro-bold {
  font-weight: 700;
}

/* Font Weight: ITC Avant Garde Gothic */
.itc-avant-garde-gothic {
  font-weight: 500;
}

/* Font Sizes */
.fs-8 {
  font-size: 0.5rem !important;
}

.fs-10 {
  font-size: 0.625rem !important;
}
.fs-11 {
  font-size: 0.6875rem !important;
}
.fs-12 {
  font-size: 0.75rem !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}
.fs-20 {
  font-size: 1.25rem !important;
}
.fs-22 {
  font-size: 1.375rem !important;
}
.fs-24 {
  font-size: 1.5rem !important;
}

.fs-26 {
  font-size: 1.625rem !important;
}

.fs-28 {
  font-size: 1.75rem !important;
}

.fs-30 {
  /* font-size: 1.875rem; */
  @apply text-[1.5rem] md:text-[1.875rem];
}
