.root {
  --text-decoration: none;
  --color: var(--clr-primary-100);
  color: var(--color);
  text-decoration: var(--text-decoration);
}
.primary {
  --color: var(--clr-primary-100);
}
.secondary {
  --color: var(--clr-secondary-300);
}
.underlined {
  --text-decoration: underline;
}
