.root {
  --color: black;
  --main-color: black;
  --width: 100%;
  --padding-x: 1rem;
  --padding-y: 1rem;
  --border-radius: 5px;
  --border-width: 1px;
  position: relative;
  display: flex;
}
.primary {
  --border-color: var(--clr-blue-100);
  --color: var(--clr-black-100);
}
.secondary {
  --border-color: var(--clr-secondary-200);
  --color: var(--clr-grey-100);
  --label-color: rgba(0, 0, 0, 0.7);
  --active-color: var(--clr-primary-300);
  --placeholder-color: rgba(0, 0, 0, 0.5);
}
.default {
  --main-color: inherit;
}

.error {
  --label-color: var(--active-color) !important;
  --border-color: var(--active-color);
}

.outline {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  width: auto;
}
.outline .label {
  text-align: start;
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.25em;
  color: var(--label-color);
}
.outline textarea {
  padding-right: min(5%, 0.9375rem);
  padding-left: min(5%, 1.875rem);
  box-sizing: border-box;
  background: #ffffff;
  @apply pt-1;
  border: 1px solid var(--clr-blue-100);
  border-radius: 0.1875rem;
  width: auto;
  min-height: 4.9375rem;
}
.outline .input::placeholder {
  color: var(--clr-secondary-200);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.outline .input:focus {
  outline: none;
  border-color: var(--clr-primary-100);
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.iconContainer .icon {
  display: flex;
}
@media only screen and (max-width: 600px) {
  .iconContainer .input[data-collapse] {
    width: 0;
    transform: scaleX(0);
    margin-right: auto;
  }
}
