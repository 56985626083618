/**
*Project:Traceability Platform
*File:detail-wizard
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: 100%;
  /* background: blue; */
  height: 100%;
}
.content {
  height: calc(100% - 4.9375rem);
  border-top: 0.0625rem solid rgba(128, 128, 128, 0.29);
}
@media only screen and (max-width: 600px) {
  .content {
    box-sizing: border-box;
    padding-bottom: 4rem;
    height: calc(100% - 2.6875rem);
  }
}
.backButton {
  background: none;
}
.backButton svg {
  color: #99a1a3;
  font-size: 1.2rem;
}
.textWhite {
  color: white;
}

.base:hover {
  cursor: pointer;
}
.secondaryHover:hover {
  background: rgba(237, 113, 54, 0.1);
}
