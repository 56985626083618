.root {
  --main-color: var(--clr-blue-100);
  --color: var(--clr-black-100);
  --width: 100%;
  --padding-x: 1rem;
  --padding-y: 1rem;
  --border-radius: 5px;
  --shadow-width: 1px;
  --shadow-color: red;
  --border-width: 1px;
  --border-color: red;
  position: relative;
  display: flex;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px rgba(0, 0, 0, 0.2);
}
.fileInput {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  /* min-height: 56px; */
  background: rgba(0, 0, 0, 0);
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
}
.fileInput input {
  display: none;
}

.fieldset {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 2.6875rem;
  color: var(--color);
  border-radius: var(--border-radius);
  background: var(--background-color);
  /* background: red; */
  width: 100%;
  height: 100%;
  border: var(--border-color) solid var(--border-width);
  padding: 1px;
  transition: all 100ms;
}
.legend {
  margin-left: 0.5rem;
  visibility: var(--legend-visibility);
  max-width: var(--legend-dimension);
  max-height: var(--legend-dimension);
  /* font-size: 0.4rem; */
  line-height: 0.1rem;
  transition: all 100ms;
  font-size: var(--label-font);
}
.label {
  position: absolute;
  z-index: 3;
  left: 0;
  transform: translate(var(--translateX), var(--translateY));
  transform-origin: left top;
  transition: all 100ms;
  color: var(--label-color);
  font-size: var(--label-font);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.primary {
  --main-color: var(--clr-blue-100);
  --color: var(--clr-black-100);
}
.secondary {
  --border-color: var(--clr-secondary-200);
  --color: var(--clr-grey-100);
  --label-color: rgba(0, 0, 0, 0.7);
  --label--not-active: rgba(0, 0, 0, 0.7);
  --active-color: var(--clr-primary-300);
  --placeholder-color: rgba(0, 0, 0, 0.5);
}

.dark {
  --background-color: inherit;
  --border-color: var(--clr-secondary-500);
  --color: var(--clr-secondary-100);
}
.dark:focus-within {
  --border-color: var(--clr-primary-300);
  --color: var(--clr-primary-300);
}
.dark:focus-within input {
  color: black;
}
.fill {
  --border-color: none;
}

.borderless {
  border: none;
  background: none;
}
.default {
  --background-color: inherit;
}
.outline {
  border: 1px dashed #5c6970;
  box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
  border-radius: 3px;
}

.error {
  --label-color: var(--active-color) !important;
  --border-color: var(--active-color);
}
