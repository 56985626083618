.root {
  display: flex;
  background: white;
  width: 100%;
  /* height: 100%; */
  flex-direction: column;
  align-items: flex-start;
}
.heading {
  margin-bottom: 2rem;
  color: var(--clr-black-200);
}
.cards {
  width: 100%;
  display: grid;
  @apply xl:grid-cols-4 gap-8 grid-cols-1 lg:grid-cols-2;
}
.charts {
  /* background: blue; */
  display: grid;
  width: 100%;
  height: 100%;
  padding-top: 3.125rem;
  /*gap: min(3.125rem, 2%);*/
  /*grid-template-columns: 4fr 2fr;*/
  /*grid-template-rows: auto;*/
}

@media only screen and (max-width: 600px) {
  .cards {
    justify-content: center;
    gap: 0.6456rem;
  }
}
@media only screen and (max-width: 800px) {
  .charts {
    gap: 1rem;
    grid-template-columns: 1fr;
  }
}
