/**
*Project:Traceability Platform
*File:overview-cards
*Created by KojoGyaase
*Copyright Bentil**/
.root {
  height: 9rem;

}

.container {
  margin: auto;
  width: 60%;
  /* border: 3px solid #73AD21; */
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}
