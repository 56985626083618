/**
*Project:Traceability Platform
*File:information
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: 100%;
  margin-left: 1.6875rem;
}
.subtitle {
  font-weight: 400;
  font-size: 1rem;
  line-height: 144%;
  /* identical to box height, or 23px */
  letter-spacing: 0.005em;
  color: #99a1a3;
}
.input {
  width: 15rem;
  height: 2.6875rem;
  background: white;
}
.link {
  padding: 0;
  --color: #ed7136;
}
.select button {
  padding-left: 0;
}
.input input {
  padding: 0;
  background: white;
}
.section {
  width: 100%;
  gap: 20px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  padding-top: 1.1875rem;
  padding-bottom: 1.5625rem;
}

.section .avatar {
  flex: 1 1 6.1875rem;
  height: 6.1875rem;
  width: 6.1875rem;
  font-size: 1.5rem;
  line-height: 144%;
}
.section .textBox {
  gap: 1.5625rem;
}

.section .textBox .field {
  align-items: center;
}

.section .textBox .field .label {
  width: 40%;
  text-align: left;
  font-weight: 600;
  font-size: 1rem;
  line-height: 144%;
  letter-spacing: 0.005em;
  color: #000000;
}
.section .textBox .field .subtitle {
  width: 40%;
  text-align: left;
  position: relative;
}
.section .textBox .field .subtitle.id {
  padding-left: 1.5rem;
}
.section .textBox .field .id::before {
  display: block;
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg width='14' height='18' viewBox='0 0 14 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6914 4.63672L9.48828 0.433594C9.37109 0.316406 9.21289 0.25 9.04688 0.25H0.75C0.404297 0.25 0.125 0.529297 0.125 0.875V17.125C0.125 17.4707 0.404297 17.75 0.75 17.75H13.25C13.5957 17.75 13.875 17.4707 13.875 17.125V5.08008C13.875 4.91406 13.8086 4.75391 13.6914 4.63672ZM8.75781 1.69141L12.4336 5.36719H8.75781V1.69141ZM12.4688 16.3438H1.53125V1.65625H7.42969V5.875C7.42969 6.09256 7.51611 6.30121 7.66995 6.45505C7.82379 6.60889 8.03244 6.69531 8.25 6.69531H12.4688V16.3438Z' fill='%2399A1A3'/%3E%3C/svg%3E");
  width: 0.875rem;
  height: 1.125rem;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateY(10%);
}

.textArea {
  /* padding: 0.625rem 1.875rem; */
  width: 15.75rem;
  height: 7.125rem;
}
.textArea textarea {
  padding: 0.625rem 1.875rem;
  width: 15.75rem;
  height: 7.125rem;
}
