.root {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}
.wizard {
  display: none;
  /* transition: all 1ms; */
}
.wizard[data-active="true"] {
  display: block;
  width: 100%;
  /* transition: transform 15ms; */
}
