/**
*Project:Traceability Platform
*File:activity-stepper
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  @apply lg:w-[38.75rem] lg:h-[37.875rem] mb-10;
  background: #ffffff;
  border: 0.0625rem solid #5c6970;
  border-radius: 0.625rem;
  padding: 1.5625rem 2.6875rem 0 1.5625rem;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.stepConnector {
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-y: scroll; */
  scrollbar-width: 0;
}
.stepConnector::-webkit-scrollbar {
  display: none;
}
.stepConnector::-webkit-scrollbar-thumb {
  display: none;
}

/* .dot:first-of-type {
  margin-top: 1.3125rem;
} */
.dot {
  width: 10px;
  height: 10px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ed7136;
  position: relative;
  /* margin-bottom: 5.6875rem; */
}
.dot::after {
  content: " ";
  display: block;
  position: absolute;
  left: 45%;
  top: 10px;
  width: 0.0625rem;
  height: 5.6875rem;
  background: rgba(128, 128, 128, 0.29);
}

.activities {
  padding-left: 2.375rem;
  width: 100%;
  padding-top: 1rem;
}
.activities .activity {
  display: flex;
  flex-direction: row;
  padding-bottom: 3.5625rem;
  gap: 2.4375rem;
}
.activities .activity:last-of-type .dot::after {
  display: none;
}
/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */
.card_container {
  padding: 2px 16px;
  width: fit-content;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  /* width: -webkit-fill-available; */
  border-radius: 5px !important;
  border: #5c6970 1px solid;
  /* border: 1px solid #ddd; */
}

th, td {
  text-align: left;
  padding: 16px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}