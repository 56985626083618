/**
*Project:Traceability Platform
*File:users-overview-cards
*Created by KojoGyaase 
*Copyright Bentil**/
/* .root {
  flex: 1 1 14.5rem;
  @apply !h-[9rem] md:min-h-[9rem] lg:h-[7.1875rem]  lg:max-w-[20.75rem];
} */
.root {
  width: 100%;
  /* min-width: */
  /* @apply !h-[9rem] md:min-h-[9rem] lg:max-h-[7.1875rem]  lg:max-w-[332px]; */
  @apply !h-[9rem] md:min-h-[9rem] lg:max-h-[7.1875rem];
}
