.root {
  display: flex;
  background: white;
  width: 100%;
  /* height: 100%; */
  flex-direction: column;
  align-items: flex-start;
}
.heading {
  margin-bottom: 2rem;
  color: var(--clr-black-200);
}
.cards {
  width: 100%;
  display: grid;
  @apply xl:grid-cols-4 gap-8 grid-cols-1 lg:grid-cols-2;
}
.charts {
  /* background: blue; */
  display: grid;
  width: 100%;
  height: 100%;
  padding-top: 3.125rem;
  /*gap: min(3.125rem, 2%);*/
  /*grid-template-columns: 4fr 2fr;*/
  /*grid-template-rows: auto;*/
}
.menu {
  width: 12.8125rem;
  height: 5.6875rem;
  background: #ffffff;
  box-shadow: 0rem 0rem 1.25rem rgba(0, 0, 0, 0.05);
  border-radius: 0.625rem;
  --translateX: -10%;
}
.item {
  font-family: "Lufga";
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #495057;
  @apply flex items-center justify-center py-0 !h-[45px];
}
.item:last-of-type {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  /* border-bottom: 0.625rem; */
}
@media only screen and (max-width: 600px) {
  .cards {
    justify-content: center;
    gap: 0.6456rem;
  }
}
@media only screen and (max-width: 800px) {
  .charts {
    gap: 1rem;
    grid-template-columns: 1fr;
  }
}


/* Float four columns side by side */
.column {
  float: left;
  width: 25%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding */
.row {margin: 0 -5px;}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}