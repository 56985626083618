/**
*Project:Traceability Platform
*File:loader
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: 100vw;
  height: 100vh;
  background: var(--clr-black-100);
  @apply flex flex-col items-center justify-center gap-[50px];
}
