.root {
}

.sm100 {
  font-size: 0.625rem;
  line-height: 0.8125rem;
}
.sm200 {
  font-size: 0.875rem;
  line-height: 1.125rem;
}
.lg100 {
  font-size: 1.5rem;
  line-height: 144%;
  /* identical to box height, or 2.1875rem */
  letter-spacing: 0.005em;
}
.lg200 {
  font-size: 1.875rem;
  line-height: 144%;
  /* identical to box height, or 2.1875rem */
  letter-spacing: 0.005em;
}
.md100 {
  font-size: 1rem;
  line-height: 144%;
}
.md200 {
  font-size: 1.125rem;
  line-height: 1.3125rem;
}
.bold100 {
  font-weight: lighter;
}
.bold600 {
  font-family: "Lufga Bold", serif;
}
.bold500 {
  font-weight: 500;
}

.bold700 {
  font-weight: 700;
}
.bold800 {
  font-weight: 800;
}
.bold900 {
  font-weight: 900;
}
