.modalOverlay {
  background: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.modalOverlay > div {
  background: rgba(0, 0, 0, 0.1) !important;
  width: 100vw;
  display: flex;
  border: none !important;
  height: 100vh;
  inset: 0 !important;
  padding: 0 !important;
  position: fixed !important;
  z-index: 99;
}
/* .modalOverlay.side {
  position: fixed;
  right: 0;
}
.modalOverlay.side > div {
  background: none !important;
  width: fit-content;
  display: flex;
  border: none !important;
  height: 100vh;
  inset: 0 !important;
  padding: 0 !important;
  position: fixed !important;
  right: 0 !important;
  z-index: 99;
  margin-left: auto;
}
.modalOverlay.side > div {
} */
