/**
*Project:Traceability Platform
*File:invitation-success
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
