/* .root {
  --color: black;
  --background-color: black;
  --width: auto;
  --padding-x: 1rem;
  --padding-y: 1rem;
  --border-radius: 4px;
  --shadow-width: 1px;
  --shadow-color:none;
  --border-width: 1px;
  --border-color:none
  --aspect-ratio: none;
  display: flex;
  justify-content: center;
    padding: 1rem;
}
.root:active * {
  transform: scale(0.9);
  transition: all 100ms;
}
.root:active {
  transform: scale(0.99);
  transition: all 100ms;
}
@media only screen and (max-width: 600px) {
  .root:active {
    transform: scale(0.9);
    transition: all 100ms;
  }
}
.root:not(.default) {
  align-items: center;
  border: var(--border-width) solid var(--border-color);
  color: var(--color);
  aspect-ratio: var(--aspect-ratio);
  border-radius: var(--border-radius);
  background: var(--background-color);
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px rgba(0, 0, 0, 0.14),
    0px 0px 0px rgba(0, 0, 0, 0.2);
}

.default {
  cursor: pointer;
}
.pill {
  --border-radius: 100rem;
}
.primary {
  --background-color: var(--clr-primary-100);
  --border-color: var(--clr-blue-100);
  --color: var(--clr-secondary-600);
}
.secondary {
  --border-color: var(--clr-secondary-200);
   --background-color: var(--clr-primary-100);
  --color: var(--clr-secondary-200);
}
.secondary:hover{
  --border-color: var(--clr-primary-100);
  --color: var(--clr-primary-100);
}
.dark {
  --background-color: var(--clr-secondary-100);
  --border-color: var(--clr-secondary-500);
  --color: var(--clr-secondary-600);
}
.fill {
  --border-color: none;
}
.outline {
  --color: var(--background-color);
  --background-color: none;
}
.icon {
  --background-color: none;
  --border-color: none;
  --color:var;
}
.square {
  --aspect-ratio: 1;
}

.sm100 {
  --padding-x: 0.1875rem;
  --padding-y: 0.5625rem;
}
.md100 {
  --padding-x: 0.375rem;
  --padding-y: 0.7813rem;
}
.md200 {
  --padding-y: 0.4375rem;
  --padding-x: 1.25rem;
}
.lg100 {
  --padding-x: 0.5625rem;
  --padding-y: 1rem;
} */
.root {
  --main-color: var(--clr-primary-100);
  --text-color: white;
  color: var(--text-color);
  background: var(--main-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.0625rem;
  transition: all 50ms;
}
.root:active {
  transform: scale(0.99);
  transition: all 10ms;
  transform-origin: center;
}
.root:not(.icon):hover {
  box-shadow: 0 0 1rem 0.1rem var(--main-color);
}
.root.icon:hover {
  text-shadow: 0.01rem 0rem var(--main-color);
}
.outline {
  background: none !important;
  border: 1px solid var(--main-color);
}
.primary {
  --main-color: var(--clr-primary-100);
  --text-color: white;
}

.secondary {
  --main-color: #99a1a3;
  --text-color: #20201f;
}

.icon {
  background: none;
  border: none;
}
.mdHeight {
  @apply lg:h-[3rem] h-[2.6rem];
}
.lgHeight {
  height: 3.375rem;
}
.pill {
  border-radius: 100rem;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
