.root {
  width: 100%;
}
.gi-header {
  margin-bottom: 5rem;
}

.gi-subtitle {
  font-weight: 400;
  font-size: 1rem;
  line-height: 144%;
  /* identical to box height, or 23px */
  letter-spacing: 0.005em;
  color: #99a1a3;
  @apply my-4 lg:my-0;
}
.section {
  width: 100%;
  gap: 15px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  padding-top: 1.1875rem;
  padding-bottom: 1.5625rem;
  @apply flex;
}
.grid-section {
  width: 100%;
  display: grid;
  grid-template-columns: 6.1875rem auto;
  gap: 1.25rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.3);
  padding-top: 1.1875rem;
  padding-bottom: 1.5625rem;
}

.section.no-border {
  border-bottom: unset;
}
.gi-field {
  display: grid;
  grid-template-columns: 2fr 4fr;
  min-height: 2.6875rem;
  align-items: center;
}
@media (max-width: 800px) {
  .gi-field {
    display: grid;
    grid-template-columns: 1fr;
    min-height: 2.6875rem;
  }
}
.gi-field label {
  text-align: left;
  font-family: "Lufga Bold", serif;
  font-size: 1rem;
  line-height: 144%;
  letter-spacing: 0.005em;
  color: #000000;
}
.gi-field .input {
  /* width: 80% !important; */
  background: white;
  max-width: 18.75rem;
}
.gi-field input,
.gi-field textarea,
.gi-field .select {
  width: 80% !important;
  background: white;
  max-width: 15rem;
}
.gi-field .gi-subtitle {
  width: 80% !important;
  max-width: 15rem;
}
.gi-inputs input {
  width: 80% !important;
  background: white;
  max-width: 23.9375rem;
}
.btn-md {
  @apply w-[10rem];
}
.btn-lg {
  @apply w-[15.625rem];
}

/* auth forms */
.authForm {
  /* background: red; */
  width: 90%;
  max-width: 31rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  align-items: stretch;

  @apply px-3 lg:px-0 mx-auto lg:gap-[2.5rem] gap-[1rem];
}
.authForm.reset-password {
  max-width: 35.75rem;
  gap: 0;
}
.authForm .otp {
  min-height: 2.6rem;
  /* white */
  background: #ffffff;
  border: 0.0625rem solid #5c6970;
  /* shadow-base */
  box-shadow: 0rem 0.0625rem 0.125rem rgba(31, 41, 55, 0.08);
  border-radius: 0.3125rem;
  @apply min-w-[2.6rem] min-h-[2.8rem] lg:min-w-[3.25rem] lg:min-h-[3.375rem];
}
.authForm .subtitle {
  font-weight: 400;
  @apply text-sm lg:text-base text-[#99A1A3] !text-center mb-5  lg:mb-16 lg:max-w-[90%];
}
.authForm.reset-password .content {
  @apply lg:mx-auto lg:max-w-[90%];
}
.authForm.reset-password .button {
  @apply mx-auto lg:max-w-[90%];
}

.authForm .input {
  width: auto;
}

.authForm .otherOption {
  margin-top: 1.5831rem;
  margin-bottom: 1.5831rem;
}
.authForm .link {
  margin-top: auto;
  margin-bottom: 4.0625rem;
}
.authForm .button {
  width: 100%;

  /* min-height: 2.5rem; */
}
.authForm .header {
  font-weight: 600;
  font-size: 3rem;
  line-height: 144%;
  letter-spacing: 0.005em;
  color: var(--clr-black-100);
  margin-top: auto;
}
.authForm .link {
  color: var(--clr-secondary-300);
}
.authForm .resetLink {
  @apply my-10;
  text-align: center;
  color: #8c8c8c;
}
@media only screen and (max-width: 600px) {
  .authForm .header {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 244%;
    letter-spacing: 0.005em;
    color: var(--clr-black-100);
    margin-top: 1rem;

    text-align: center;
  }
  .authForm .otherOption {
    @apply my-1;
  }
  .authForm .link {
    text-align: center;
    margin-bottom: auto;
  }
  .authForm .firstNam,
  .authForm .lastName {
    width: 100%;
  }
}
