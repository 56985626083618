/**
*Project:Traceability Platform
*File:users-table
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: auto;
  display: flex;
  flex-direction: column;
}
.tableContainer {
  flex: 1 2 auto;
  overflow-x: auto;
  max-width: 100%;
}
.table {
  width: 100%;
}
.table .row:hover {
  background: var(--clr-primary-400);
}
.table .heading {
  text-align: left;
}
.table .cell {
  text-align: left;
}
.filterButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8125rem;
  --color: #99a1a3;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8125rem;
  line-height: 1.0625rem;
  width: fit-content;
}
.filterButton svg {
  color: #99a1a3;
}
.filterButton:hover svg {
  color: var(--clr-primary-100);
}
.filterButton:hover span {
  color: var(--clr-primary-100);
}
.collapse {
  gap: 1.25rem;
  margin-bottom: 1rem;
  max-height: auto;
  transform: scaleY(1);
  transition: transform 500ms;
  transform-origin: top;
}
.collapse[data-collapse="true"] {
  max-height: 0;
  transform: scaleY(0);
  transition: transform 500ms;
  transform-origin: top;
}
.search {
  width: 271px;
  border: 1px solid #dadada;
  border-radius: 5px;
  background: #ffffff;
  /* padding: 10px 0; */
  box-sizing: border-box;
}
.search .input input {
  border: none;
  padding-left: 0.9375rem;
  height: 40px;
}
.search svg {
  color: #878787;
  margin-right: 0.75rem;
}
.optionButton {
  height: 40px;
}
.optionButton svg {
  color: #878787;
}
.roles button {
  height: 40px;
  width: 189px;
}
.user button,
.invite button {
  width: 135px;
  height: 40px;
  background: red;
}
.label {
  color: #878787;
}
.selectRow {
  width: fit-content;
  margin-top: 2.75rem;
  margin-bottom: 1.875rem;
}
.select button span {
  font-size: 0.8125rem;
  line-height: 1.0625rem;
  /* identical to box height */
  color: #99a1a3;
  height: 1.0625rem;
}
.select button {
  /* font-size: 0.8125rem;
  line-height: 1.0625rem; */
  /* identical to box height */
  color: #99a1a3;
}
.box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
.box.actions {
  /* gap: 1.875rem; */
}
.box.actions button {
  --padding-x: 0;
  /* gap: 1.875rem; */
}
.box.actions svg {
  color: #9a9ab0;
}
.box.actions .dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 220px;
  height: 240px;
  /* Dropdown */
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.box.actions .dropdown .item {
  /* padding: 0.75rem 1.25rem; */
  display: flex !important;
  flex-direction: row;
  align-items: center;
  width: 100%;
  /* width: 13.75rem; */
  height: 2.5rem;
  box-sizing: border-box;
  font-weight: 200;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: "Lufga";
  /* padding-left: min(2.5rem, 10%); */
  /* identical to box height */
  color: #242423;
}
@media only screen and (max-width: 1000px) {
  .box {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0.2rem;
    text-align: center;
  }
  .table .heading {
    text-align: center;
    padding: 0 0.4rem;
  }
  .table .cell {
    text-align: center;
    /* padding: 0; */
  }
  .collapse {
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1000px) {
  .header {
    height: 5rem;
  }
}
@media only screen and (max-width: 1000px) {
  .header {
    height: 5rem;
  }
  .box {
    flex-direction: column;
  }
}
