/**
*Project:Traceability Platform
*File:avatar
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: 2.1875rem;
  height: 2.1875rem;
  font-family: "Uber Move";
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8125rem;
  line-height: 120%;
  letter-spacing: 0.003em;
}
.root[data-color="green"] {
  background: #e9ffef;
  color: #009834;
}
.root[data-color="red"] {
  background: #ffe9e9;
  color: #da474d;
}
.root[data-color="purple"] {
  color: #60509c;
  background: rgba(96, 80, 156, 0.35);
}
.root[data-color="yellow"] {
  background: #feedc8;
  color: #cd951d;
}
.root[data-color="blue"] {
  background: #e0f2ff;
  color: #0ca5fc;
}
.root[data-color="none"] {
  background: #e0e0e0;
  color: #9a9ab0;
}
