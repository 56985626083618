/**
*Project:Traceability Platform
*File:organization
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  width: 100%;
  height: 100%;
  display: block;
}
.tableContainer {
  flex: 1 2 auto;
  overflow-x: auto;
  max-width: 100%;
}
.table {
  width: 100%;
}
.table .row:hover {
  background: var(--clr-primary-400);
}
.table .heading {
  text-align: left;
}
.table .cell {
  text-align: left;
}
