/**
*Project:Traceability Platform
*File:table
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
  border-spacing: 0;
}
.header {
  /* border-top: 1px solid rgba(128, 128, 128, 0.29);
  border-bottom: 1px solid rgba(128, 128, 128, 0.29); */
  margin-bottom: 0.9375rem;
}
.header th {
  border-top: 1px solid rgba(128, 128, 128, 0.29);
  border-bottom: 1px solid rgba(128, 128, 128, 0.29);
  border-left: 0;
  border-right: 0;
  padding: 1.375rem 0rem 1.125rem 0;
  display: table-cell;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #000000;
}
.cell {
  display: table-cell;
  padding: 1.1875rem 0rem 1.125rem 0;
  vertical-align: middle;
  /* border-top: 1px solid #f3f5f7; */
  border-bottom: 1px solid #f3f5f7;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 120%;
  /* identical to box height, or 16px */
  letter-spacing: 0.003em;
  color: #495057;
}
@media only screen and (max-width: 1000px) {
  .header th {
    border-top: 1px solid rgba(128, 128, 128, 0.29);
    border-bottom: 1px solid rgba(128, 128, 128, 0.29);
    border-left: 0;
    border-right: 0;
    padding: 1.375rem 0rem 1.125rem 0;
    display: table-cell;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
  }
  .cell {
    display: table-cell;
    padding: 1.1875rem 0rem 1.125rem 0;
    vertical-align: middle;
    /* border-top: 1px solid #f3f5f7; */
    border-bottom: 1px solid #f3f5f7;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    /* identical to box height, or 16px */
    letter-spacing: 0.003em;
    color: #495057;
  }
}
