.root {
  --color: var(--clr-primary-300);
  display: flex;
  border-radius: 0.3125rem;
  transition: all 1s;
}
.blue {
  --color: var(--clr-blue-200);
}
.green {
  --color: var(--clr-green-200);
}
.pink {
  --color: var(--clr-primary-200);
}
.orange {
  --color: var(--clr-primary-300);
}
.grey {
  --color: var(--clr-grey-800);
}
.yellow {
  --color: rgba(234, 183, 74, 0.3);
}
.fill {
  background: var(--color);
}
.outline {
  border: 1px solid var(--color);
}
.red {
  --color: #ffe9e9;
}
