.root {
}
.header {
  background: none;
  border: none;
  display: grid;
}

.header:active {
  transform: scale(0.99999);
  transition: all 1s;
}
.caret {
  grid-column: e-start/e-end;
  grid-row: c-start/d-end;
  /* background: #777777; */
  display: flex;
  align-items: center;
  font-size: 2rem;
  width: 100%;
  height: 100%;
  transition: all 1s;
  transform-style: preserve-3d;
  backface-visibility: visible;
}
.caret[data-expand="true"] {
  transform: rotateX(180deg);
}
