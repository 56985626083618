/**
*Project:Traceability Platform
*File:trace-analytics
*Created by KojoGyaase 
*Copyright Bentil**/
.root {
}
.card {
  background: #fdf6ef;
  border-radius: 10px;
  padding: 1.75rem;
  @apply lg:w-[25.8125rem] lg:h-[21.9375rem];
}
