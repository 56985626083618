@tailwind base;
@tailwind components;
@tailwind utilities;
*:root {
  --clr-primary-100: #ed7136;
  --clr-primary-200: rgba(237, 113, 54, 0.3);
  --clr-primary-300: rgba(229, 144, 52, 0.3);
  --clr-primary-400: #fdf6ef;
  --clr-primary-500: #f3e9ea;
  --clr-secondary-100: #d3cec4;
  --clr-secondary-200: #d1d1d1;
  --clr-secondary-300: #8c8c8c;
  --clr-secondary-400: #99a1a3;
  --clr-secondary-500: #e0e0e0;
  --clr-secondary-600: #ffffff;
  --clr-black-100: #0f0f0f;
  --clr-black-200: #242423;
  --clr-black-300: #2a2a2a;
  --clr-blue-100: #99a1a3;
  --clr-blue-200: rgba(115, 202, 177, 0.3);
  --clr-grey-100: #777777;
  --clr-grey-200: #f5f5f5;
  --clr-grey-300: #969eae;
  --clr-grey-400: #919191;
  --clr-grey-500: #2a2a2a;
  --clr-grey-600: #565656;
  --clr-grey-700: #a4a4a4;
  --clr-grey-800: #929493;
  --clr-black-100: #242423;
  --clr-black-200: #131313;
  --clr-green-100: #66b134;
  --clr-green-200: rgba(150, 184, 72, 0.3);
  --clr-yellow-100: #cd951d;
  --clr-yellow-200: #feedc8;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  margin: 0;
  font-family: "Lufga", "Roboto", "Uber Move", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .debug-css * {
  outline: 3px solid limegreen !important;
  box-sizing: border-box;
  background: rgba(0 100 0/0.1);
} */
p,
h1,
h2,
h3,
h4,
h5,
li,
ul,
ol {
  margin: 0;
}
input {
  box-shadow: none !important;
}
